import * as React from "react"
import IdentityScene from "../components/identity-scene"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Identity = () => (
  <Layout>
    <Seo title="Identity" />
    <h1>Identity</h1>
    <IdentityScene></IdentityScene>
  </Layout>
)

export default Identity
