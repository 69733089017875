import React, { useEffect, useRef } from "react"
import * as THREE from "three"
import { GUI } from "dat.gui"

const IdentityScene = () => {

  const containerRef = useRef(null);
  let sceneRef = useRef(null);
  let cameraRef = useRef(null);
  let rendererRef = useRef(null);
  let frontMeshRef = useRef(null);
  let backMeshRef = useRef(null);
  let planeMeshRef = useRef(null);
  let guiRef = useRef(null);

  const params = {
    animate: true,
    highRes: false,
    speed: 0.02,
  };

  const uniforms = {
    "time": { value: 1.0 },
    "timeoffset": { value: 0.0 },
    "offset": { value: 1.0 },
    "resolution": { value: new THREE.Vector2() }
  };

  const vertexShader = `varying vec3 vUv;

    void main()
    {
      vUv = position;
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
      gl_Position = projectionMatrix * mvPosition;
    }`;

  const fragmentShader = `uniform vec2 resolution;
    varying vec3 vUv;
    uniform float time;
    uniform float offset;
    uniform float timeoffset;
    
    const lowp float speed = 3.;
    const lowp vec3 cycle = vec3(.2523/3.,.9239745/3.,.43192/3.);
    const lowp vec3 col_base = vec3(0.4,0.4,1.);
    varying lowp vec3 col_base_cycle;
    varying lowp vec3 col_process;
    varying lowp vec3 col_blend;
    
    float blendOverlay(float base,float blend){
      return(blend==1.)?blend:min(base/(1.-blend),1.);
    }

    void main(){
      vec2 st=gl_FragCoord.xy/resolution.xy;
      st.x*=resolution.x/resolution.y;

      float t = timeoffset + time;
      
      vec3 col_base_cycle = vec3(
        sin(t*cycle.x)+.4,
        sin(t*cycle.y)+.4,
        sin(t*cycle.z)+1.);

      vec3 p = offset + 0.075 * vUv;
      float m = t/speed;
      
      for(int i=1;i<4;i++)
      {
        vec3 newp=p;
        newp.x+=3./float(i)*sin(float(i)*p.y+m);
        newp.y+=1./float(i)*cos(float(i)*p.x+m);
        p=newp;
      }

      vec3 col_process = vec3(
        sin(p.x)+.4,
        sin(p.y)+.4,
        sin(p.x+p.y));
      
      vec3 col_blend=vec3(
        blendOverlay(col_base_cycle.r,col_process.r),
        blendOverlay(col_base_cycle.g,col_process.g),
        blendOverlay(col_base_cycle.b,col_process.b));

        float o = smoothstep(0.0, 1.0, 1.0 - ((vUv.z) * 0.06)) * 0.5;
    
        gl_FragColor = vec4(col_blend, 1.0 - (vUv.z * vUv.z * 0.001));
    }`;

  const frontMaterial = new THREE.ShaderMaterial({

    uniforms: uniforms,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    transparent: true,
    side: THREE.FrontSide,

  });

  const backMaterial = new THREE.ShaderMaterial({

    uniforms: uniforms,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    transparent: true,
    side: THREE.BackSide,

  });

  /**
   * 
   * initial scene setup
   * 
   **/

  useEffect(() => {

    const { current } = containerRef;

    sceneRef.current = new THREE.Scene()
    cameraRef.current = new THREE.PerspectiveCamera(75, current.offsetWidth / current.offsetHeight, 0.1, 1000)
    cameraRef.current.position.z = 40

    rendererRef.current = new THREE.WebGLRenderer({antialiasing:true, preserveDrawingBuffer: true});
    rendererRef.current.setPixelRatio( window.devicePixelRatio * 1.5 );
    rendererRef.current.setSize(current.offsetWidth, current.offsetHeight)
    current.appendChild(rendererRef.current.domElement)


    const geometry = new THREE.SphereGeometry(20, 60, 60);

    // FRONT
    
    frontMeshRef.current = new THREE.Mesh(geometry, frontMaterial);
    frontMeshRef.current.rotation.y = Math.PI;
    //frontMeshRef.current.rotation.x = Math.PI/-2;
    frontMeshRef.current.renderOrder = 2;
    sceneRef.current.add(frontMeshRef.current)

    // BACK
    
    backMeshRef.current = new THREE.Mesh(geometry, backMaterial);
    backMeshRef.current.rotation.y = Math.PI;
    //backMeshRef.current.rotation.x = Math.PI/-2;
    backMeshRef.current.renderOrder = 1;
    sceneRef.current.add(backMeshRef.current)

    // PLANE

    /*
    const planeGeometry = new THREE.PlaneGeometry(50, 50, 1);

    planeMeshRef.current = new THREE.Mesh(planeGeometry, frontMaterial);
    planeMeshRef.current.position.x = -50;
    sceneRef.current.add(planeMeshRef.current)
    */
    
    // GUI

    guiRef.current = new GUI()
    guiRef.current.add(uniforms["timeoffset"], 'value', 0, 1000).step(0.01).name('time offset')
    guiRef.current.add(params, 'speed', 0, 10).step(0.01).name('speed')
    guiRef.current.add(params, 'animate').name('animate')
    guiRef.current.add(params, 'highRes').name('high res').onChange((value) => {

      rendererRef.current.antialias = value;

      if (value === true) {

        rendererRef.current.setPixelRatio(window.devicePixelRatio);

      } else {

        rendererRef.current.setPixelRatio(1);

      }

    });

    return function cleanup() {

      guiRef.current.destroy()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  /**
   * 
   * requestAnimationFrame
   * https://css-tricks.com/using-requestanimationframe-with-react-hooks/
   * 
   **/

  const requestRef = React.useRef();
  const previousTimeRef = React.useRef();

  const animate = time => {

    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;

      // set uniform values
      if (params.animate) {
        uniforms["time"].value += deltaTime * params.speed * 0.01;
      }

      //frontMeshRef.current.rotation.y -= 0.01;
      //backMeshRef.current.rotation.y = frontMeshRef.current.rotation.y;

      //backMeshRef.current.rotation.y = frontMeshRef.current.rotation.y;

      rendererRef.current.render(sceneRef.current, cameraRef.current)

    }


    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);

  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div style={{
      position: `fixed`,
      left: 0, right: 0, top: 0, bottom: 0,
      zIndex: -10,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
    }}>
      <div ref={containerRef} style={{ width: `100vw`, height: `100vh` }}></div>
    </div>
  )

}

export default IdentityScene